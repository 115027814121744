import React, { useEffect } from "react";

import { createAdminView, ViewComponent } from "@admin/view";
import { navigate } from "@utils/navigate";

import LoginHistory from "./login-history";

const Admin: ViewComponent = (props) => {
	useEffect(() => {
		navigate("/admin/login-history", {
			replace: true,
		});
	}, []);

	return <LoginHistory {...props} />;
};

export default createAdminView(Admin, {
	title: "Admin",
});
